<template>
  <v-select
    v-model="entity"
    :label="label"
    :items="entities"
    :outlined="type === 'outlined'"
    :loading="loading"
    @change="entitySelected"
    class="mb-0 pb-0"
  />
</template>

<script>
import EntidadesBdgdService from '@/services/EntidadesBdgdService';

export default {
  name: 'SelectBdgdEntity',
  props: {
    type: {
      type: String,
      default: 'solo'
    },
    label: {
      type: String,
      default: 'Entidade da BDGD'
    }
  },
  data() {
    return {
      entity: null,
      entities: [],
      loading: false
    };
  },
  mounted() {
    this.getEntities();
  },
  methods: {
    getEntities() {
      this.loading = true;
      EntidadesBdgdService.getEntidades()
        .then((response) => (this.entities = response.data))
        .finally(() => (this.loading = false));
    },
    entitySelected(entitySelected) {
      this.$emit('entitySelected', entitySelected);
    }
  }
};
</script>
